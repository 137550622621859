import { LitElement, html } from '@polymer/lit-element'
import './grid-layout'

class WebsiteApp extends LitElement {
    createRenderRoot() {
        return this
    }

    render() {
        return html`
            <style>
                .website-grid {
                    position: relative;
                    display: grid;
                    height: 100vh;
                    width: 100vw;
                    grid-template-columns:  minmax(7vh, max-content) max-content minmax(7vh, max-content);
                    grid-template-rows: 7vh min-content 1fr;
                }
            </style>
            <grid-layout class="website-grid"></grid-layout>
        `
    }
}

customElements.define('website-app', WebsiteApp)