import { LitElement, html } from '@polymer/lit-element'
import './background-art'
import './website-header'

class GridLayout extends LitElement {
    createRenderRoot() {
        return this
    }

    render() {
        return html`
            <style>
               .full-screen {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100vw;
                    height: 100vh;
                }

                .behind-everything {
                    z-index: -1;
                }
                
                .header-grid-area {
                    grid-row: 2 / 3;
                    grid-column: 2 / 3;
                }
            </style>
            <background-art class="full-screen behind-everything"></background-art>
            <website-header class="header-grid-area"></website-header>
        `
    }
}

customElements.define('grid-layout', GridLayout)